import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Badge from "components/Badge";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "badge"
    }}>{`Badge`}</h1>

    <Playground __position={0} __code={'<Badge color=\"primary\">Primary</Badge>\n<Badge color=\"secondary\">Secondary</Badge>\n<Badge color=\"success\">Success</Badge>\n<Badge color=\"danger\">Danger</Badge>\n<Badge color=\"warning\">Warning</Badge>\n<Badge color=\"info\">Info</Badge>\n<Badge color=\"light\">Light</Badge>\n<Badge color=\"dark\">Dark</Badge>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Badge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge color="primary" mdxType="Badge">Primary</Badge>
  <Badge color="secondary" mdxType="Badge">Secondary</Badge>
  <Badge color="success" mdxType="Badge">Success</Badge>
  <Badge color="danger" mdxType="Badge">Danger</Badge>
  <Badge color="warning" mdxType="Badge">Warning</Badge>
  <Badge color="info" mdxType="Badge">Info</Badge>
  <Badge color="light" mdxType="Badge">Light</Badge>
  <Badge color="dark" mdxType="Badge">Dark</Badge>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/badge/" target="_blank">Reactstrap Badge</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      